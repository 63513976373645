<template>
  <Modal v-model="AddBrandModal" :mask-closable="false" width="450">
    <p slot="header" style="color:#f60;text-align:center">
        <Icon type="ios-information-circle"></Icon>
        <span>添加品牌</span>
    </p>

    <Form
      ref="brandData"
      :model="brandData"
      :label-width="90"
    >

      <template >
        <FormItem prop="platformBrandId" label="平台品牌库" :rules="{ required: true, type: 'number', message: '请选择平台品牌库' }">
          <Select v-model="brandData.platformBrandId" filterable clearable placeholder="请选择品牌" @on-change="handleChangeBrand" style="width: 300px;">
            <Option v-for="item in platformBrandArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>

        <FormItem style="margin-bottom: -5px;">
          <p class="remark m-t-10">
            tips： 此选项允许您输入品牌关键字进行搜索选择

            <span v-if="showNotFoundBtn">，若未找到对应的品牌请 <Button size="small" class="m-l-5" icon="md-star" type="primary" @click="handleChangeCreateType(true)">未找到品牌</Button></span>
          </p>
        </FormItem>

        <FormItem v-if="selectPlatformBrands && selectPlatformBrands.parentName" label="一级行业">
          <Tag color="cyan">{{selectPlatformBrands.parentName}}</Tag>
          <p class="remark">{{ selectPlatformBrands.parentDefinition }}</p>
        </FormItem>
        <FormItem v-if="selectPlatformBrands && selectPlatformBrands.industryName" label="二级行业">
          <Tag color="cyan">{{selectPlatformBrands.industryName}}</Tag>
          <p class="remark">{{ selectPlatformBrands.definition }}</p>
        </FormItem>
      </template>

      <!-- <template v-else>
        <FormItem prop="name" label="品牌备注" :rules="{ required: true, message: '请输入品牌备注', trigger: 'blur' }">
          <Input v-model.trim="brandData.name" placeholder="请输入品牌备注"  style="width: 300px;" />
        </FormItem>
        <FormItem style="margin-bottom: -5px;">
          <p class="remark m-t-10">
            tips： 此选项需要您输入品牌备注，若要选择平台品牌，请选择
            <Button size="small" class="m-l-5" icon="md-star" type="primary" @click="handleChangeCreateType(false)">品牌库</Button>
          </p>
        </FormItem>
      </template> -->

      <FormItem v-if="defualtIndustryType !== 2"  prop="industryId" label="自定义行业" :rules="{ required: true, type: 'number', message: '请选择所属自定义行业', trigger: 'change' }">
        <Select v-model="brandData.industryId" placeholder="选择所属品牌所属行业"  style="width: 300px;">
          <Option
            v-for="industry in industrydata"
            :value="industry.id"
            :key="industry.id"
            >{{ industry.name }}</Option
          >
        </Select>
      </FormItem>

      <!-- 如果是平台行业，同时是新建品牌库，那么需要显示一级行业和二级行业 -->
      <!-- <template v-if="defualtIndustryType===2 ">
        <FormItem label="一级行业" style="margin-bottom: 20px !important;" prop="firstIndustryId" :rules="{ required: true, message: '请选择品牌所属一级行业', trigger: 'change' }">
          <Select v-model="brandData.firstIndustryId" placeholder="请选择一级行业分类" style="width: 300px;"  @on-change="handleChangeFirstIndustry">
            <Option
              v-for="firstIndustry in firstIndustryArray"
              :value="firstIndustry.id"
              :key="firstIndustry.id"
              >{{ firstIndustry.name }}</Option
            >
          </Select>
          <p v-if="brandData.firstIndustryId" class="remark">{{ getFirstIndustryName(brandData.firstIndustryId) }}</p>
        </FormItem>
        <FormItem label="二级行业" style="margin-bottom: 20px !important;" prop="industryId" :rules="{ required: true, message: '请选择品牌所属二级行业', trigger: 'change' }">
          <Select v-model="brandData.industryId" placeholder="请选择二级行业分类" style="width: 300px;">
            <Option
              v-for="secondIndustry in secondIndustryArray"
              :value="secondIndustry.id"
              :key="secondIndustry.id"
              >{{ secondIndustry.name }}</Option
            >
          </Select>
          <p v-if="brandData.industryId" class="remark">{{ getSecondIndustryName(brandData.industryId) }}</p>
        </FormItem>
      </template> -->

      <FormItem  prop="name" label="品牌备注" :rules="{ required: false}">
        <Input v-model.trim="brandData.name" :rows="3" placeholder="请输入品牌备注" type="textarea" />
      </FormItem>
    </Form>

    <div slot="footer">
      <Button type="text" @click="AddBrandModal = false">取消</Button>
      <Button type="primary" @click="handleAddBrand('brandData')">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { listAllBrands, getIndustryById, listFirstIndustry, listSecondIndustry } from '@/api/dw/platform'
import { getRootIndustryList } from '@/api/os/industry'

export default {
  data () {
    return {
      defualtIndustryType: this.$store.getters.token.publisherSetting.industryType, // 后台配置的行业类型

      showNotFoundBtn: this.$store.getters.token.publisherSetting.notFoundBrandButton, // 是否展示未找到品牌按钮

      AddBrandModal: false,

      industrydata: [],
      platformBrandArray: [],
      firstIndustryArray: [],
      secondIndustryArray: [],
      // isCreated: false,

      selectPlatformBrands: null,

      brandData: {
        name: '',
        platformBrandId: null,
        industryId: null,
        firstIndustryId: null
      }
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    showModal () {
      this.$refs.brandData.resetFields()
      // if (this.defualtIndustryType !== 2) {
      //   this.brandData.industryId = this.industrydata[0].id
      // }

      this.selectPlatformBrands = null
      // this.isCreated = false

      this.AddBrandModal = true
    },
    initPageData () {
      // 获取所有客户行业
      getRootIndustryList({
        publisherId: this.$store.getters.token.userInfo.publisherId,
        type: 1
      }).then((res) => {
        this.industrydata = res
      })

      // 获取所有的一级行业清单
      listFirstIndustry().then(res => {
        this.firstIndustryArray = res
      })

      // 获取所有平台品牌
      listAllBrands().then(res => {
        this.platformBrandArray = res
      })
    },
    handleChangeCreateType (val) {
      // this.isCreated = val
      this.$refs.brandData.resetFields()
      this.brandData.platformBrandId = this.platformBrandArray.find(x => x.id === -1).id
      this.handleChangeBrand()
    },
    handleChangeBrand () {
      if (this.brandData.platformBrandId) {
        const platformBrand = this.platformBrandArray.find(x => x.id === this.brandData.platformBrandId)

        getIndustryById({ industryId: platformBrand.industryId }).then(res => {
          this.selectPlatformBrands = res
        })
      } else {
        this.selectPlatformBrands = null
      }
    },
    handleAddBrand (dataname) {
      this.$refs[dataname].validate((valid) => {
        if (valid) {
          const brandBean = {
            name: this.brandData.name,
            industryName: null,
            industryId: this.brandData.industryId,
            platformBrandId: null,
            platformBrandName: null,
            platformIndustryId: null,
            platformIndustryName: null
          }

          // if (this.isCreated) { // 新建品牌
          //   const selectIndustry = (this.defualtIndustryType === 2) ? this.secondIndustryArray.find(x => x.id === this.brandData.industryId)
          //     : this.industrydata.find(x => x.id === this.brandData.industryId)
          //   brandBean.industryName = selectIndustry.name
          // } else { // 使用平台品牌库
          //   const platformBrand = this.platformBrandArray.find(x => x.id === this.brandData.platformBrandId)
          //   brandBean.platformBrandId = platformBrand.id
          //   brandBean.platformBrandName = platformBrand.name
          //   brandBean.platformIndustryId = this.selectPlatformBrands.industryId
          //   brandBean.platformIndustryName = this.selectPlatformBrands.industryName

          //   // 采用平台行业，需要赋默认值
          //   if (this.defualtIndustryType === 2) {
          //     brandBean.industryId = brandBean.platformIndustryId
          //     brandBean.industryName = brandBean.platformIndustryName
          //   } else {
          //     // 获取选项中的值
          //     brandBean.industryName = this.industrydata.find(x => x.id === this.brandData.industryId).name
          //   }
          // }
          const platformBrand = this.platformBrandArray.find(x => x.id === this.brandData.platformBrandId)
          brandBean.platformBrandId = platformBrand.id
          brandBean.platformBrandName = platformBrand.name
          brandBean.platformIndustryId = this.selectPlatformBrands.industryId
          brandBean.platformIndustryName = this.selectPlatformBrands.industryName

          // 采用平台行业，需要赋默认值
          if (this.defualtIndustryType === 2) {
            brandBean.industryId = brandBean.platformIndustryId
            brandBean.industryName = brandBean.platformIndustryName
          } else {
            // 获取选项中的值
            brandBean.industryName = this.industrydata.find(x => x.id === this.brandData.industryId).name
          }
          // brandBean.industryName = this.industrydata.find(x => x.id === this.brandData.industryId).name
          this.$emit('on-success', brandBean)
          this.AddBrandModal = false
        }
      })
    },
    handleChangeFirstIndustry () {
      // 获取二级分类
      listSecondIndustry({ firstIndustryId: this.brandData.firstIndustryId }).then(res => {
        this.brandData.industryId = null
        this.secondIndustryArray = res
      })
    },
    getFirstIndustryName (industryId) {
      const selectIndustry = this.firstIndustryArray.find(x => x.id === industryId)
      return selectIndustry.description
    },
    getSecondIndustryName (industryId) {
      const selectIndustry = this.secondIndustryArray.find(x => x.id === industryId)
      return selectIndustry.description
    }
  }
}
</script>
